import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dashboards`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2013/11/RTD-02.gif",
        "alt": "Sailthru_Dashboards"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2013/11/snapshot-02.png",
        "alt": "Sailthru_Dashboards"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2013/11/preso.png",
        "alt": "Sailthru_Dashboards"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2013/11/dashboard.png",
        "alt": "Sailthru_Dashboards"
      }}></img></p>
    <hr></hr>
    <p>{`Visual guide for sailthru platform and applications.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/10/visual_assets.png",
        "alt": "sailthru_style_guide"
      }}></img></p>
    <hr></hr>
    <p>{`Smart Strategies`}</p>
    <p>{`With Smart Strategies you’ll be able to effectively manage the customer lifetime journey. I’ve created a simple interface that allows you to to communicate to your users as they follow a path that you’ve laid out for them.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/10/samrt-01.png",
        "alt": "Sailthru_Dashboards"
      }}></img></p>
    <p>{`Each path is a possible journey that your user may have. I call each path: Tactic. For each strategy you can make as many tactics as you want. A tactic looks like this:`}</p>
    <p>{`User Register, after one day, if s/he didn’t complete the profile, send a followup email.`}</p>
    <p>{`OR it can be user purchases something, after 30 minutes, if the purchase contains “apple” and the price is above “100$” , add user to the Loyal List.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/10/path.png",
        "alt": "path"
      }}></img></p>
    <p>{`There are a lot of different combinations you can make for your tactics but all of the follow a very simple logic:`}</p>
    <p>{`If an event happened, after a wait time (optional), check a set of criteria, if the criteria is true, set the Action.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/10/logic.png",
        "alt": "SLogiv"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/10/ss1.png",
        "alt": "Smart strategies"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/10/data-feed.png",
        "alt": "Data feed"
      }}></img></p>
    <p>{`User Look Up Page
With the user lookup page, every Sailthru account has the ability to look up history and activity for any user imported into the Sailthru system. This page shows the lifetime values of a user’s clicks, opens, pageviews, and purchases, along with the past communications they may have received.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2013/05/userlookup_02.png",
        "alt": "USer Lookup"
      }}></img></p>
    <p>{`see full page view`}</p>
    <p>{`Product Page
The products page displays purchase data about specific products over the last 30 days, ranking the products starting with the product that gained the highest revenue to the product that acquired the least revenue.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2013/05/product_page1.png",
        "alt": "Product Page"
      }}></img></p>
    <p>{`see full page view`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      